@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #000000;
}

@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Finlandica&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.about,
.works {
  background-image: url(./assests/images/studio.png);
  background-repeat: no-repeat;
  background-size: cover;
}
